import {
  createRouter,
  createWebHistory
} from 'vue-router'

import HomePage from './pages/HomePage.vue'
import AboutPage from './pages/AboutPage.vue'
import ContactPage from './pages/ContactPage.vue'
import ServicePage from './pages/ServicePage.vue'
import ProjectsPage from './pages/ProjectsPage.vue'
import AgenciesPage from './pages/AgenciesPage.vue'
import ClientPage from './pages/ClientPage.vue'
import SubService from './pages/SubService.vue'

import NotFound from './pages/NotFound.vue'

let router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: HomePage
    },
    {
      path: '/about',
      component: AboutPage
    },
    {
      path: '/contact',
      component: ContactPage
    },
    {
      path: '/projects',
      component: ProjectsPage,
    },
    {
      path: '/agencies',
      component: AgenciesPage,
    },
    {
      path: '/services/:id',
      props: true,
      component: ServicePage
    },
    {
      path: '/suppliers/:id',
      props: true,
      component: ClientPage
    },
    {
      path: '/subservices/:id',
      props: true,
      component: SubService
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound
    }
  ],
  scrollBehavior() {
    return { top: 0, left: 0 }
  },
})

// import $ from 'jquery'

// router.beforeEach(async () => {
//   // console.log(from)
//   // console.log(to)
//   // document.getElementById('main-nav-mobi').style.display = 'none'
// })

export default router