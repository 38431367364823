<template>
  <div class="inner">
    <div class="hover-effect">
      <div class="gallery-image" :style="`background-image: url(${image})`">
        <div class="gradient"></div>
        <h5
          style="
            position: absolute;
            color: #fff;
            bottom: 15px;
            left: 22px;
            width: 90%;
            margin: 0px;
            text-align: left;
          "
          class="truncate-multi"
        >
          {{ title }}
        </h5>
      </div>

      <div class="text">
        <div class="icon">
          <a href="#" class="zoom-popup" @click="selectImage(index)">
            <span
              class="finance-icon-plus"
              style="font-weight: 900; font-size: 24px"
              aria-hidden="true"
            ></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["index", "image", "title"],
  emits: "selectImage",
  methods: {
    selectImage(index) {
      this.$emit("selectImage", index);
    },
  },
};
</script>