<template>
  <TheLoader v-if="loading" />
  <div id="wrapper">
    <div id="page" class="clearfix">
      <TheHeader :services="services" />
      <router-view></router-view>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import TheLoader from "./components/TheLoader.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    TheLoader,
  },
  data() {
    return {
      loading: true,
    };
  },
  provide: {
    imagePath: "https://readygroupbucket.s3.eu-central-1.amazonaws.com/images/",
  },
  async mounted() {
    await this.$store.dispatch("pages/contact");
    await this.$store.dispatch("services/services");
    this.loading = false;
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
  },
};
</script>

<style>
.service-item .inner,
.gallery-box .inner {
  border-radius: 15px;
  overflow: hidden;
}

.service-item .inner {
  border: 1px solid #eee;
}
</style>
