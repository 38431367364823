<template>
  <TheLoader v-if="loading" />
  <div id="main-content" class="site-main clearfix" v-if="!loading">
    <div id="content-wrap">
      <div id="site-content" class="site-content clearfix">
        <div id="inner-content" class="inner-content-wrap">
          <div class="page-content">
            <div class="container">
              <div class="row clearfix spacer-t-md spacer-b-sm">
                <div class="col-md-12">
                  <div class="ready-headings style-1">
                    <h2 class="heading clearfix">{{ subservice.title }}</h2>
                    <div class="sep clearfix page-title"></div>
                  </div>
                  <swiper
                    :style="`height: ${imageHeight}px; margin-bottom: 50px; border-radius:15px; margin-right: 15px`"
                    :slides-per-view="1"
                    :space-between="15"
                    :navigation="navigation"
                    :observer="true"
                    :observeParents="true"
                  >
                    <button id="prev-el">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.867"
                        height="11.234"
                        viewBox="0 0 6.867 11.234"
                      >
                        <g
                          id="chevron-down_4_"
                          data-name="chevron-down (4)"
                          transform="translate(1.25 9.466) rotate(-90)"
                        >
                          <path
                            id="Path"
                            d="M0,3.849,3.849,0,7.7,3.849"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            stroke-width="3"
                          />
                        </g>
                      </svg>
                    </button>
                    <button id="next-el">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.867"
                        height="11.234"
                        viewBox="0 0 6.867 11.234"
                      >
                        <g
                          id="chevron-down_4_"
                          data-name="chevron-down (4)"
                          transform="translate(1.768 9.466) rotate(-90)"
                        >
                          <path
                            id="Path"
                            d="M0,0,3.849,3.849,7.7,0"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            stroke-width="3"
                          />
                        </g>
                      </svg>
                    </button>
                    <swiper-slide
                      v-for="(image, index) in subservice.photos"
                      :key="index"
                    >
                      <div
                        class="inner"
                        :style="`height: ${imageHeight}px;
                          background-color: #eee;
                          background-image: url(${imagePath}${image});
                          background-size: cover;
                          border-radius:15px;
                          background-position: center;`"
                      ></div>
                    </swiper-slide>
                  </swiper>
                </div>

                <div class="col-md-12">
                  <div class="ready-portfolio-detail style-1">
                    <div class="inner">
                      <div class="text-wrap">
                        <div class="desc">
                          <span v-html="subservice.summary"> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row clearfix" v-if="subservice.content">
                <div
                  class="col-md-12"
                  v-for="(text, index) in subservice.content"
                  :key="index"
                >
                  <h6 class="title" style="color: #a10000">
                    <span>
                      {{ text.header }}
                    </span>
                  </h6>
                  <div class="desc spacer-b-sm">
                    <span v-html="text.body"> </span>
                  </div>

                  <div
                    class="col-sm-12 spacer-b-sm"
                    style="padding-left: 0px; padding-right: 0px"
                    v-if="text.items"
                  >
                    <div class="ready-headings style-1 clearfix">
                      <div
                        v-for="item in text.items"
                        :key="item"
                        class="ready-button sub-card"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import TheLoader from "../components/TheLoader.vue";

SwiperCore.use([Navigation]);

export default {
  components: { Swiper, SwiperSlide, TheLoader },
  props: ["id"],
  inject: ["imagePath"],
  data() {
    return {
      navigation: {
        nextEl: "#next-el",
        prevEl: "#prev-el",
        disabledClass: "disabled-nav-button",
      },
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch("services/subservice", this.id);
    this.loading = false;
  },
  computed: {
    subservice() {
      let s = this.$store.getters["services/subservice"];
      return s;
    },
    imageHeight() {
      let width = document.documentElement.clientWidth;
      return width > 991 ? 500 : width > 600 ? 400 : 300;
    },
  },
};
</script>

<style scoped>
.container {
  padding-bottom: 50px;
}

img {
  width: 100%;
  height: auto;
}

#prev-el {
  position: absolute;
  top: 48%;
  left: 10px;
  z-index: 100;
}

#next-el {
  position: absolute;
  top: 48%;
  right: 10px;
  z-index: 100;
}
</style>

<style>
.sub-card {
  margin-right: 30px;
  margin-bottom: 10px;
  background-color: #ddd;
  color: #000;
}
</style>