import useApi from '../../useApi'

export default {
  about: async context => {
    const url = context.rootGetters.url('about')
    const response = await useApi.get(url)
    context.commit('setAbout', response.about)
  },
  home: async context => {
    const url = context.rootGetters.url('home')
    const response = await useApi.get(url)
    context.commit('setHome', response.home)
  },
  contact: async context => {
    const url = context.rootGetters.url('contact')
    const response = await useApi.get(url)
    context.commit('setContact', response.contact)
  }
}