<template>
  <teleport to="body">
    <div id="backdrop">
      <div @click="close" id="bg"></div>
      <dialog open class="modal-container" @click.prevent>
        <img class="image-container" :src="image" />

        <button class="close-button" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
          >
            <path
              id="Union_1"
              data-name="Union 1"
              d="M1242.5,7.5,1235,15l7.5-7.5L1235,0l7.5,7.5L1250,0l-7.5,7.5L1250,15Z"
              transform="translate(-1234 1)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
          </svg>
        </button>
      </dialog>
    </div>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: ["image"],
  async mounted() {
    document.querySelector("html").style.overflowY = "hidden";
  },
  methods: {
    close(arg1 = true) {
      if (arg1) {
        document.querySelector("html").style.overflowY = "auto";
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: -18px;
  right: -18px;
  padding: 12px 14px;
  border-radius: 50px;
}

#backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #0a0a0a88;
  z-index: 90;
  overflow-y: auto;
}

#bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #0a0a0a88;
  z-index: 90;
  overflow-y: auto;
}

.modal-container {
  position: relative;
  top: 5vh;
  width: 600px;
  height: auto;
  overflow: visible;
  border-radius: 5px;
  border: none;
  z-index: 91;
  margin-bottom: 10vh;
  display: block;
}

img {
  border-radius: 5px;
}

@media only screen and (max-width: 700px) {
  .modal-container {
    position: absolute;
    width: 90%;
    margin-left: 0px;
    left: 5%;
  }
}
</style>