<template>
  <button :id="id">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.867"
      height="11.234"
      viewBox="0 0 6.867 11.234"
    >
      <g
        id="chevron-down_4_"
        data-name="chevron-down (4)"
        transform="translate(1.768 9.466) rotate(-90)"
      >
        <path
          id="Path"
          d="M0,0,3.849,3.849,7.7,0"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  props: ["id"]
};
</script>