<template>
  <TheLoader v-if="loading" />
  <div class="col-md-12" v-else-if="services">
    <swiper :slides-per-view="slidesNumber" :space-between="15">
      <swiper-slide
        class="service-item clearfix"
        v-for="service in services"
        :key="service.id"
      >
        <!-- <div class="service-item"> -->
        <div class="inner">
          <!-- v-for="service in services" :key="service.id" -->
          <div class="thumb">
            <img :src="service.thumbnail" alt="Image" />
            <div class="meta">
              <span class="post-date">SERVICES</span>
            </div>
          </div>
          <div class="text-wrap bg-light-grey">
            <h3 class="title">
              <router-link :to="`/services/${service.id}`" class="truncate">
                {{ service.title }}
              </router-link>
            </h3>

            <span v-html="service.content" class="truncate2"></span>

            <div class="post-btn">
              <router-link
                :to="`/services/${service.id}`"
                class="small ready-button outline ol-accent"
              >
                READ MORE
              </router-link>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import TheLoader from "./TheLoader.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    TheLoader,
  },
  data() {
    return {
      slidesNumber: 3,
      loading: true,
    };
  },
  async mounted() {
    let width = document.documentElement.clientWidth;
    this.slidesNumber = width > 600 ? 2 : 1;
    await this.$store.dispatch("services/services");
    this.loading = false;
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 900px) {
  .service-item .text-wrap {
    padding: 15px 15px 12px;
  }
}

/* .service-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */

/* .service-item > .inner {
  width: 48%;
} */
</style>

<style>
.truncate2 * {
  display: none;
}

.truncate2 *:first-child {
  display: block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
</style>