<template>
  <div id="main-content" class="site-main clearfix">
    <div
      class="image"
      style="background-image: url(assets/img/slider/2.jpg)"
    ></div>
    <div class="container">
      <div class="row">
        <div class="container text">
          <h1>Page Not Found</h1>
          <router-link to="/">Return to Home</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  object-position: center;
  opacity: 0.8;
}

img {
  width: 100%;
  height: auto;
}

.text {
  position: absolute;
  top: 30vh;
}

.text h1 {
  font-size: 70px;
  color: #fff;
  font-weight: 900;
}

.text a {
  color: #fff;
  font-weight: 500;
  font-size: 24px;
}

.text a:hover {
  color: aqua;
}

@media only screen and (max-width: 700px) {
  .text h1 {
    font-size: 30px;
  }
}
</style>