<template >
  <TheLoader v-if="loading" />
  <div v-else-if="service">
    <!-- <div id="featured-title" class="featured-title">
      <div id="featured-title-inner" class="ready-container clearfix">
        <div class="featured-title-inner-wrap">
          <div class="featured-title-heading-wrap">
            <h1 class="featured-title-heading">SERVICES</h1>
          </div>
          <div id="breadcrumbs">
            <div class="breadcrumbs-inner">
              <div class="breadcrumb-trail">
                <span class="trail-begin"> SERVICES </span>
                <span class="sep"
                  ><i class="finance-icon-chevron-right"></i
                ></span>
                <span class="trail-end">{{ service.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div id="main-content" class="site-main clearfix">
      <div id="content-wrap">
        <div id="site-content" class="site-content clearfix">
          <div id="inner-content" class="inner-content-wrap">
            <div class="page-content">
              <div class="row-video">
                <div class="container">
                  <div class="row spacer-t-md spacer-b-md">
                    <div class="col-md-5">
                      <div class="ready-headings style-1 clearfix">
                        <h2 class="heading clearfix">{{ service.title }}</h2>
                        <div class="sep clearfix"></div>

                        <div v-html="service.content"></div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <swiper
                        :style="`height: ${imageHeight}px; margin-bottom: 50px`"
                        :slides-per-view="1"
                        :space-between="15"
                        :navigation="navigation"
                        :observer="true"
                        :observeParents="true"
                      >
                        <button id="prev-el">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.867"
                            height="11.234"
                            viewBox="0 0 6.867 11.234"
                          >
                            <g
                              id="chevron-down_4_"
                              data-name="chevron-down (4)"
                              transform="translate(1.25 9.466) rotate(-90)"
                            >
                              <path
                                id="Path"
                                d="M0,3.849,3.849,0,7.7,3.849"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="3"
                              />
                            </g>
                          </svg>
                        </button>
                        <button id="next-el">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.867"
                            height="11.234"
                            viewBox="0 0 6.867 11.234"
                          >
                            <g
                              id="chevron-down_4_"
                              data-name="chevron-down (4)"
                              transform="translate(1.768 9.466) rotate(-90)"
                            >
                              <path
                                id="Path"
                                d="M0,0,3.849,3.849,7.7,0"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="3"
                              />
                            </g>
                          </svg>
                        </button>
                        <swiper-slide
                          v-for="(image, index) in service.photos"
                          :key="index"
                        >
                          <div
                            class="inner"
                            :style="`height: ${imageHeight}px;
                          background-color: #eee;
                          background-image: url(${image});
                          background-size: cover;
                          background-position: center;
                          border-radius: 15px`"
                          ></div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>

                  <div class="row" v-if="subs">
                    <div class="ready-lines left line2-full style-1 width-100">
                      <div class="line-1"></div>
                      <div class="line-2"></div>
                    </div>
                    <div class="col-md-12">
                      <div class="ready-headings style-1 clearfix">
                        <h3
                          class="sub-heading clearfix"
                          style="font-weight: bold"
                        >
                          <br />
                          Our Services Include:
                          <br />
                        </h3>
                      </div>
                    </div>
                    <div class="col-sm-12 spacer-b-sm">
                      <div class="suppliers-container">
                        <div
                          class="col-xs-12 col-sm-6 col-md-4 service-item spacer-b-sm"
                          v-for="supplier in list"
                          :key="supplier.id"
                        >
                          <SubServiceCard :item="supplier" :service="service" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import TheLoader from "../components/TheLoader.vue";
import SubServiceCard from "../components/SubServiceCard.vue";

SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    SubServiceCard,
    TheLoader,
  },
  props: ["id"],
  inject: ["imagePath"],
  data() {
    return {
      navigation: {
        nextEl: "#next-el",
        prevEl: "#prev-el",
        disabledClass: "disabled-nav-button",
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        reverseDirection: false,
      },
      loading: true,
    };
  },
  // async updated() {
  // },
  watch: {
    async id() {
      this.loading = true;
      await this.$store.dispatch("services/service", this.id);
      this.loading = false;
    },
  },
  async mounted() {
    await this.$store.dispatch("services/service", this.id);
    this.loading = false;
  },
  computed: {
    service() {
      return this.$store.getters["services/service"];
    },
    imageHeight() {
      let width = document.documentElement.clientWidth;
      return width > 991 ? 400 : width > 600 ? 250 : 150;
    },
    subs() {
      return (
        this.service.subservices.length > 0 || this.service.suppliers.length > 0
      );
    },
    list() {
      return [...this.service.subservices, ...this.service.suppliers];
    },
  },
};
</script>


<style scoped>
#prev-el {
  position: absolute;
  top: 48%;
  left: 10px;
  z-index: 100;
}

#next-el {
  position: absolute;
  top: 48%;
  right: 10px;
  z-index: 100;
}

.swiper-container {
  border-radius: 15px;
}

.clearfix {
  margin-bottom: 20px;
}

.service-button {
  margin-right: 40px;
  margin-bottom: 10px;
}
</style>