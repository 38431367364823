<template>
  <ProjectsGallery
    v-if="projects && gallery"
    :projects="projects"
    :show="gallery"
    :initial-slide="initialSlide"
    @close="closeGallery"
  />

  <div class="row-gallery">
    <div class="container">
      <div class="projects-header spacer-b-md">
        <div class="ready-headings style-1 clearfix">
          <h2 class="heading clearfix">OUR PROJECTS</h2>
          <div class="sep clearfix"></div>
        </div>
        <div>
          <button-prev id="prev-el" />
          <button-next id="next-el" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <swiper
            class="partners-slider"
            :slides-per-view="slidesNumber"
            :space-between="15"
            :navigation="projectsNav"
            :autoplay="autoplay"
          >
            <swiper-slide
              class="project-item clearfix gallery-box"
              v-for="(project, index) in projects"
              :key="project.id"
            >
              <ProjectCard
                :index="index"
                :title="project.title"
                :image="project.photo"
                @selectImage="openGallery"
              />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import ProjectsGallery from "./ProjectsGallery.vue";
import ButtonPrev from "./ButtonPrev.vue";
import ButtonNext from "./ButtonNext.vue";
import ProjectCard from "./ProjectCard.vue";

SwiperCore.use([Navigation, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    ProjectsGallery,
    ProjectCard,
    ButtonPrev,
    ButtonNext,
  },
  data() {
    return {
      gallery: false,
      projectsNav: {
        nextEl: "#next-el",
        prevEl: "#prev-el",
        disabledClass: "disabled-nav-button",
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
        reverseDirection: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch("services/projects");
  },
  computed: {
    projects() {
      return this.$store.getters["services/projects"];
    },
    slidesNumber() {
      let width = document.documentElement.clientWidth;
      return width > 1100 ? 3 : width > 700 ? 2 : 1;
    },
  },
  methods: {
    openGallery(index) {
      this.initialSlide = index;
      this.gallery = true;
    },
    closeGallery() {
      this.gallery = false;
    },
  },
};
</script>

<style scoped>
.partners-slider {
  border-radius: 15px;
  overflow: hidden;
}
</style>

<style >
.projects-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.disabled-nav-button {
  opacity: 0;
  cursor: default;
}

/* https://medium.com/@dvmsoni3132/easy-truncate-multi-line-ellipsis-with-pure-css-e0d24d68078e */
.truncate-multi {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.gradient {
  position: absolute;
  background-color: #0003;
  width: 100%;
  height: 50%;
  bottom: 0;
  background: linear-gradient(180deg, #0000 0%, #000f 100%);
}

.project-item:hover > .inner > .hover-effect > .gallery-image > div,
.project-item:hover > .inner > .hover-effect > .gallery-image > h5 {
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.2s ease-in-out;
}

.gallery-image {
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1200px) {
  .gallery-image {
    height: 230px;
  }
}

@media only screen and (max-width: 700px) {
  .gallery-image {
    height: 350px;
  }
}
</style>
