<template>
  <div class="inner" v-if="item">
    <div class="thumb" :style="page ? 'height:200px' : 'height: 300px'">
      <div
        :style="`
              background-image: url(${item.photos[0]}); 
              height:100%; 
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            `"
      ></div>
      <!-- <div class="meta">
              <span class="post-date">SERVICES</span>
            </div> -->
    </div>
    <div v-if="item.logo" :class="logoClass">
      <img :src="item.logoPath" :alt="item.name" />
    </div>
    <div class="text-wrap bg-light-grey" :class="wrapperClass">
      <h3 class="title">
        <router-link
          v-if="item.logo"
          :to="`/suppliers/${item.id}`"
          class="truncate"
        >
          {{ item.title }}
        </router-link>
        <router-link v-else :to="`/subservices/${item.id}`" class="truncate">
          {{ item.title }}
        </router-link>
      </h3>

      <span v-html="item.summary" class="excerpt-text truncate2"> </span>

      <div class="post-btn spacer-t-sm">
        <router-link
          v-if="item.logo"
          :to="`/suppliers/${item.id}`"
          class="truncate"
        >
          READ MORE
        </router-link>
        <router-link v-else :to="`/subservices/${item.id}`" class="truncate">
          READ MORE
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "page"],
  data() {
    return { slidesNumber: 3 };
  },
  mounted() {
    let width = document.documentElement.clientWidth;
    this.slidesNumber = width > 900 ? 3 : width > 600 ? 2 : 1;
  },
  computed: {
    logoClass() {
      return this.page && this.page === "suppliers" ? "logo-lg" : "logo";
    },
    wrapperClass() {
      return this.page && this.page === "suppliers" ? "wrapper-lg" : "wrapper";
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 900px) {
  .service-item .text-wrap {
    padding: 15px 15px 12px;
  }
}

.wrapper {
  padding-top: 55px !important;
}

.wrapper-lg {
  padding-top: 80px !important;
}

.logo {
  height: 80px;
  width: 80px;
  position: absolute;
  border-radius: 50%;
  left: 30px;
  top: 260px;
  /* background-color: #fff; */
  display: grid;
  place-items: center;
  overflow: hidden;
}

.logo-lg {
  height: 120px;
  width: 120px;
  position: absolute;
  border-radius: 50%;
  left: 30px;
  top: 140px;
  /* background-color: #fff; */
  display: grid;
  place-items: center;
  overflow: hidden;
}

.text-wrap,
.logo + .text-wrap {
  min-height: 220px;
}

.logo-lg + .text-wrap {
  min-height: 280px;
}

.text-wrap > .post-btn {
  position: absolute;
  bottom: 25px;
}
</style>