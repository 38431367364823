<template>
  <teleport to="body">
    <transition name="gallery">
      <div id="gallery-backdrop" v-if="show">
        <button class="close-button" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
          >
            <path
              id="Union_1"
              data-name="Union 1"
              d="M1242.5,7.5,1235,15l7.5-7.5L1235,0l7.5,7.5L1250,0l-7.5,7.5L1250,15Z"
              transform="translate(-1234 1)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
          </svg>
        </button>
        <dialog open class="modal-container">
          <swiper
            class="image-container"
            :space-between="10"
            :initialSlide="initialSlide"
            :navigation="navigation"
            :thumbs="{
              swiper: thumbsSwiper,
              slideThumbActiveClass: 'active-thumb',
            }"
          >
            <button id="gallery-prev-el">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.867"
                height="11.234"
                viewBox="0 0 6.867 11.234"
              >
                <g
                  id="chevron-down_4_"
                  data-name="chevron-down (4)"
                  transform="translate(1.25 9.466) rotate(-90)"
                >
                  <path
                    id="Path"
                    d="M0,3.849,3.849,0,7.7,3.849"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </button>
            <button id="gallery-next-el">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.867"
                height="11.234"
                viewBox="0 0 6.867 11.234"
              >
                <g
                  id="chevron-down_4_"
                  data-name="chevron-down (4)"
                  transform="translate(1.768 9.466) rotate(-90)"
                >
                  <path
                    id="Path"
                    d="M0,0,3.849,3.849,7.7,0"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </button>
            <swiper-slide
              v-for="project in projects"
              :key="project.id"
              style="height: 100%"
            >
              <div
                class="main-image-container"
                :style="`background-image:url(${project.photo})`"
              ></div>
              <h6 class="project-title">{{ project.title }}</h6>
            </swiper-slide>
          </swiper>
          <swiper
            class="thumbs"
            @swiper="setThumbsSwiper"
            watch-slides-visibility
            watch-slides-progress
            :slides-per-view="thumbsNumber"
            :space-between="10"
          >
            <swiper-slide
              v-for="project in projects"
              :key="project.id"
              class="thumb-bg"
              :style="`background-image:url(${project.photo})`"
            >
            </swiper-slide>
            <swiper-slide
              v-if="!page"
              class="thumb-bg thumb-last"
              @click="openProjects"
            >
              <h5>View More Projects</h5>
            </swiper-slide>
          </swiper>
        </dialog>
      </div>
    </transition>
  </teleport>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/thumbs/thumbs.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Thumbs, Navigation } from "swiper";

SwiperCore.use([Thumbs, Navigation]);

export default {
  components: { Swiper, SwiperSlide },
  emits: ["close"],
  props: ["projects", "show", "initialSlide", "page"],
  data() {
    return {
      thumbsSwiper: null,
      navigation: {
        nextEl: "#gallery-next-el",
        prevEl: "#gallery-prev-el",
        disabledClass: "disabled-nav-button",
      },
      position: null,
    };
  },
  computed: {
    thumbsNumber() {
      let width = document.documentElement.clientWidth;
      return width > 1200 ? 5 : width > 700 ? 4 : 2;
    },
  },
  beforeMount() {
    this.position = document.documentElement.scrollTop;
  },
  mounted() {
    document.querySelector("html").style.overflow = "hidden";
  },
  unmounted() {
    window.scroll(0, this.position);
    this.position = null;
    document.querySelector("html").style.overflowY = "auto";
  },
  methods: {
    close(arg1 = true) {
      if (arg1) {
        this.$emit("close");
      }
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    openProjects() {
      this.$router.push("/projects");
    },
  },
};
</script>

<style scoped>
.thumb-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.thumb-last {
  background-color: #fff;
  display: grid;
  place-content: center;
}
.thumb-last * {
  width: 100%;
  text-align: center;
}

.gallery-enter-active,
.gallery-leave-active {
  transition: all 2s ease;
}

.gallery-enter-from,
.gallery-leave-to {
  opacity: 0;
  transform: scale(0.5);
}

.gallery-enter-to,
.gallery-leave-from {
  opacity: 1;
  transform: scale(1);
}

.thumbs {
  height: 150px;
  position: absolute;
  padding: 10px 20px;
  width: 100%;
  bottom: 10px;
}

.thumbs img,
.thumbs .thumb-bg {
  opacity: 0.5;
}

.thumbs img:hover,
.thumbs .thumb-bg:hover {
  opacity: 0.8;
  cursor: pointer;
}

.active-thumb {
  opacity: 1 !important;
}

.image-container {
  position: relative;
  top: 20px;
  width: 80vw;
  max-width: 1400px;
  height: 70vh;
}

.project-title {
  color: #fff;
  padding: 5%;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 12px 14px;
  border-radius: 50px;
  z-index: 200;
}

.main-image-container {
  height: 84%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#gallery-prev-el {
  position: absolute;
  top: 39%;
  left: 10px;
  z-index: 100;
  margin-left: 2px;
  margin-right: 2px;
  padding: 9px 12px;
  border-radius: 50%;
}

#gallery-next-el {
  position: absolute;
  top: 39%;
  right: 10px;
  z-index: 100;
  margin-left: 2px;
  margin-right: 2px;
  padding: 9px 12px;
  border-radius: 50%;
}

#gallery-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #000000f8;
  z-index: 90;
  overflow-y: auto;
  overflow: hidden;
}

.modal-container {
  /* top: 10vh; */
  /* left: 20%; */
  width: 100%;
  height: 100%;
  overflow: visible;
  border: none;
  z-index: 91;
  background: #7772;
}

.main-image {
  width: 100%;
  height: auto;
}

.thumb-image {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 700px) and (orientation: portrait) {
  .modal-container {
    width: 100%;
  }

  .image-container {
    position: relative;
    top: 10vh;
    width: 100%;
    max-height: 55vh;
  }

  .thumbs {
    bottom: 80px;
  }
}

@media only screen and (max-height: 700px) and (orientation: landscape) {
  .image-container {
    position: relative;
    top: 5px;
    height: 60vh;
    width: auto;
    max-width: 60vw;
  }

  .thumbs {
    height: 100px;
    position: absolute;
    padding: 10px 10px;
    width: 100%;
    bottom: 45px;
  }
}
</style>