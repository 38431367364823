<template>
  <TheLoader v-if="loading" />
  <div id="main-content" class="site-main clearfix" v-if="info">
    <div id="content-wrap">
      <div id="site-content" class="site-content clearfix">
        <div id="inner-content" class="inner-content-wrap">
          <div class="page-content">
            <div class="row-maps">
              <div class="container">
                <div class="row spacer-t-sm">
                  <div class="col-md-12">
                    <div class="ready-headings style-1">
                      <h2 class="heading clearfix">Contact Us</h2>
                      <div class="sep clearfix spacer-b-sm"></div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <GoogleMap
                      api-key="AIzaSyB1FYMwy8GWDS7LurSRX60WTo_a9b93u1A"
                      style="width: 100%; height: 400px; margin-bottom: 50px"
                      :center="mapCenter"
                      :zoom="16"
                    >
                      <Marker
                        :options="{
                          position: mapCenter,
                          icon: {
                            url: '/assets/img/new-logo-sq.png',
                            scaledSize: { width: 40, height: 40 },
                          },
                        }"
                      />
                    </GoogleMap>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-contact bg-light-grey">
              <div class="container">
                <div class="row clearfix">
                  <div class="col-md-9">
                    <div class="ready-contact-form">
                      <form @submit.prevent class="contact-form wpcf7-form">
                        <span class="wpcf7-form-control-wrap your-name">
                          <input
                            type="text"
                            tabindex="1"
                            id="name"
                            name="name"
                            v-model="name"
                            class="wpcf7-form-control"
                            placeholder="YOUR NAME*"
                            required
                          />
                        </span>
                        <span class="wpcf7-form-control-wrap your-email">
                          <input
                            type="email"
                            tabindex="2"
                            id="email"
                            name="email"
                            v-model="email"
                            class="wpcf7-form-control"
                            placeholder="EMAIL*"
                            required
                          />
                        </span>
                        <span class="wpcf7-form-control-wrap your-phone">
                          <input
                            type="text"
                            tabindex="3"
                            id="phone9"
                            name="phone"
                            v-model="phone"
                            class="wpcf7-form-control"
                            placeholder="YOUR PHONE*"
                            required
                          />
                        </span>
                        <span class="wpcf7-form-control-wrap your-select">
                          <select tabindex="4" v-model="time" required>
                            <option :value="null" selected disabled>
                              BEST TIME TO CALL
                            </option>
                            <option
                              v-for="time in callTimes"
                              :key="time.id"
                              :value="time.time"
                            >
                              {{ time.time }}
                            </option>
                          </select>
                        </span>
                        <span class="wpcf7-form-control-wrap your-subject">
                          <input
                            type="text"
                            tabindex="5"
                            id="subject"
                            name="subject"
                            v-model="subject"
                            class="wpcf7-form-control"
                            placeholder="SUBJECT*"
                            required
                          />
                        </span>
                        <span class="wpcf7-form-control-wrap your-message">
                          <textarea
                            name="message"
                            v-model="message"
                            tabindex="6"
                            cols="40"
                            rows="10"
                            class="wpcf7-form-control wpcf7-textarea"
                            placeholder="YOUR MESSAGE*"
                            required
                          ></textarea>
                        </span>
                        <div class="wrap-submit spacer-b-md">
                          <input
                            type="submit"
                            value="CALL ME BACK"
                            class="submit wpcf7-form-control wpcf7-submit"
                            style="border-radius: 25px"
                            id="submit"
                            name="submit"
                            @click="sendEmail"
                          />
                        </div>
                      </form>
                      <div
                        class="alert-success spacer-b-sm"
                        style="padding: 10px"
                        v-if="status && sent"
                      >
                        Email sent successfully.
                      </div>
                      <div
                        class="alert-danger spacer-b-sm"
                        style="padding: 10px"
                        v-if="status && !sent"
                      >
                        Failed to send email, please try again.
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="ready-contact-info">
                      <div class="inner">
                        <div class="contact-info-item clearfix">
                          <h6 class="title margin-bottom-16">
                            <span>PHONE NUMBER</span>
                          </h6>
                          <span v-html="info.phone"></span>
                        </div>

                        <div class="contact-info-item clearfix">
                          <h6 class="title margin-bottom-16">
                            <span>EMAIL</span>
                          </h6>
                          <span v-html="info.email"></span>
                        </div>

                        <div class="contact-info-item spacer-b-md clearfix">
                          <h6 class="title">
                            <span>ADDRESS</span>
                          </h6>
                          <span v-html="info.address"></span>
                        </div>

                        <div
                          class="contact-info-item clearfix spacer-b-sm"
                          v-if="info.facebook || info.linkedin"
                        >
                          <div class="socials clearfix">
                            <span class="icons">
                              <a
                                v-if="info.facebook"
                                :href="info.facebook"
                                title="Facebook"
                                target="_blank"
                              >
                                <span
                                  class="finance-icon-Facebook"
                                  aria-hidden="true"
                                ></span>
                              </a>
                              <a
                                v-if="info.linkedin"
                                :href="info.linkedin"
                                title="Linkedin"
                                target="_blank"
                              >
                                <span
                                  class="finance-icon-Instagram"
                                  aria-hidden="true"
                                ></span>
                              </a>
                              <a
                                v-if="info.instagram"
                                :href="info.instagram"
                                title="Instagram"
                                target="_blank"
                              >
                                &nbsp;&nbsp;
                                <span>
                                  <InstagramIcon
                                    class="instagram-logo"
                                    stroke="#898989"
                                  />
                                </span>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
import InstagramIcon from "../components/InstagramIcon.vue";
import TheLoader from "../components/TheLoader.vue";

export default {
  components: { GoogleMap, Marker, TheLoader, InstagramIcon },
  data() {
    return {
      loading: true,
      center: { lat: 35.56200029797303, lng: 45.42857512638596 },
      name: "",
      email: "",
      phone: "",
      time: null,
      subject: "",
      message: "",
      status: null,
      callTimes: [
        { id: 1, time: "08:00 AM - 12:00 AM" },
        { id: 2, time: "12:00 AM - 05:00 PM" },
        { id: 3, time: "05:00 PM - 10:00 PM" },
      ],
    };
  },
  mounted() {
    this.loading = false;
  },
  watch: {
    info() {
      this.loading = false;
    },
  },
  computed: {
    info() {
      return this.$store.getters["pages/contact"];
    },
    mapCenter() {
      let location = this.info.location;
      if (location.includes(",")) {
        let coordinates = this.info.location.split(",");
        return {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1]),
        };
      }
      return this.center;
    },
  },
  methods: {
    async sendEmail() {
      this.status = null;
      this.sent = false;

      if (this.name === "") {
        return;
      }
      if (this.email === "") {
        return;
      }
      if (this.phone.length < 11) {
        return;
      }
      if (this.subject === "") {
        return;
      }
      if (this.body === "") {
        return;
      }
      if (!this.time) {
        return;
      }

      let form = new FormData();
      form.append("name", this.name);
      form.append("email", this.email);
      form.append("phone", this.phone);
      form.append("subject", this.subject);
      form.append("body", this.message);
      form.append("time", this.time);

      try {
        await this.$store.dispatch("sendMail", form);
        this.status = true;
        this.sent = true;
      } catch (error) {
        this.status = true;
        this.sent = false;
      }

      setTimeout(() => {
        this.status = false;
        this.sent = false;
      }, 5000);
    },
  },
};
</script>

<style scoped>
.row-contact {
  padding-top: 50px;
}

.p0 {
  padding: 0px;
  height: 40px;
  margin-bottom: 15px;
}

.p0 select,
.p0 input,
.p0 textarea {
  background-color: #fff;
  border-color: #fff;
}

.ready-contact-form {
  margin-bottom: 30px;
}

span.icons a {
  margin-right: 10px;
}

.instagram-logo {
  margin-bottom: -2px;
  margin-left: -8px;
}
</style>