import { createStore } from "vuex"

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import Services from './services/index'
import Pages from './pages/index'

const store = createStore({
  modules: {
    services: Services,
    pages: Pages
  },
  state() {
    return {
      server: 'https://admin.ready-engineering.com/api/',
    }
  },
  mutations,
  actions,
  getters,
})


export default store