<template>
  <TheLoader v-if="loading" />
  <div id="main-content" class="site-main clearfix" v-else-if="agencies">
    <div id="content-wrap">
      <div id="site-content" class="site-content clearfix">
        <div id="inner-content" class="inner-content-wrap">
          <div class="page-content">
            <div class="row-partner">
              <div class="container spacer-b-md spacer-t-sm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="ready-headings style-1">
                      <h2 class="heading clearfix">
                        Certifications and Agencies
                      </h2>
                      <div class="sep clearfix page-title"></div>
                    </div>
                    <div class="suppliers-container">
                      <div
                        class="col-xs-12 col-sm-6 col-md-4 service-item spacer-b-sm"
                        v-for="agency in agencies"
                        :key="agency.id"
                      >
                        <SubServiceCard :item="agency" page="suppliers" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubServiceCard from "../components/SubServiceCard.vue";
import TheLoader from "../components/TheLoader.vue";
export default {
  components: { SubServiceCard, TheLoader },
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    suppliers() {
      this.loading = false;
    },
  },
  computed: {
    agencies() {
      let agencies = [];
      this.suppliers.forEach((supplier) => {
        if (supplier.certificate) agencies.push(supplier);
      });
      return agencies;
    },
    suppliers() {
      return this.$store.getters["services/suppliers"];
    },
  },
  async mounted() {
    await this.$store.dispatch("services/suppliers");
    this.loading = false;
  },
  methods: {
    openAgency(agency) {
      this.$router.push(`/services/${agency.service.id}/supplier/${agency.id}`);
    },
  },
};
</script>

<style>
.suppliers-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.suppliers-container > div {
  padding: 1px 15px;
}

.page-title {
  margin-bottom: 20px;
}

.supplier-item {
  border-radius: 180px;
  overflow: hidden;
  width: 150px;
  height: 150px;
  object-fit: contain;
  display: grid;
  place-items: center;
}

.supplier-item.border {
  border: 2px solid #e9e9e9;
}

@media only screen and (max-width: 991px) {
  .supplier-item {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .suppliers-container > div {
    padding: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .supplier-item {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .supplier-item {
    width: 75px;
    height: 75px;
  }
}
</style>