import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      about: null,
      home: null,
      contact: null
    }
  },
  mutations,
  actions,
  getters
}