<template>
  <footer id="footer" v-if="info">
    <div id="footer-widgets" class="footer-container title-style-1">
      <div class="ready-row gutter-30">
        <div class="span_1_of_4 col">
          <div class="widget widget_text padding-left-7">
            <div class="textwidget">
              <p class="margin-bottom-22">
                <img
                  src="/assets/img/new-logo-sq.png"
                  width="120"
                  height="120"
                  alt="Finance"
                  data-retina="assets/img/new-logo-sq.png"
                  data-width="226"
                  data-height="50"
                />
              </p>
            </div>
          </div>
        </div>

        <div class="span_1_of_4 col">
          <div class="widget widget_information margin-top-6 padding-left-9">
            <h2 class="widget-title margin-bottom-43">
              <span>Ready Engineering</span>
            </h2>
            <p>
              <!-- Ready Engineering Company For Engineering Services, Industrial
              Spare Parts, Fire Fighting & Security Systems. -->
              For General Contracting and General trading
            </p>
          </div>
        </div>

        <div class="span_1_of_4 col">
          <div class="widget widget_information margin-top-6 padding-left-7">
            <h2 class="widget-title margin-bottom-43">
              <span>Address & Contact</span>
            </h2>
            <ul>
              <li class="address clearfix">
                <div class="inner">
                  <i class="finance-icon-map"></i>
                  <span class="text">IRAQ - SULAYMANIYAH </span>
                </div>
              </li>
              <li class="email clearfix">
                <div class="inner">
                  <i class="finance-icon-Email02"></i>
                  <span class="text">{{ info.main_email }}<br /></span>
                </div>
              </li>
              <li class="phone clearfix">
                <div class="inner">
                  <i class="finance-icon-Phone"></i>
                  <span class="text">{{ info.main_phone }} </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="span_1_of_4 col">
          <div class="widget widget_information margin-top-6 padding-left-9">
            <h2 class="widget-title margin-bottom-43"><span>Site Map</span></h2>
            <ul>
              <li class="address clearfix">
                <router-link to="/" class="inner">
                  <span class="text">HOME</span>
                </router-link>
              </li>
              <li class="address clearfix">
                <router-link to="/about" class="inner">
                  <span class="text">ABOUT</span>
                </router-link>
              </li>
              <li class="address clearfix">
                <router-link to="/services" class="inner">
                  <span class="text">SERVICES</span>
                </router-link>
              </li>
              <li class="address clearfix">
                <router-link to="/" class="inner">
                  <span class="text">SUPPLIERS & PRODUCTS</span>
                </router-link>
              </li>
              <li class="address clearfix">
                <router-link to="/contact" class="inner">
                  <span class="text">CONTACT</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <div id="bottom" class="clearfix style-1">
    <div id="bottom-bar-inner" class="footer-container">
      <div class="bottom-bar-inner-wrap">
        <div class="bottom-bar-content bg-">
          <div id="copyright" class="padding-left-9">
            Ready Engineering Co. &copy; 2021 Website by
            <a href="https://www.blackace.tech" target="_blank">Black Ace</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  computed: {
    info() {
      return this.$store.getters["pages/contact"];
    },
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>