<template>
  <div id="loader">
    <img src="/assets/img/loader.svg" />
  </div>
</template>

<style scoped>
#loader {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 99999;
  background-color: #fafafa;
  display: grid;
  place-items: center;
}
</style>