<template>
  <div class="inner">
    <div class="thumb">
      <img :src="logo" :alt="title" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "title", "logo"]
};
</script>

<style scoped>
.client-card {
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: 2px solid #444;
  border-radius: 10px;
}
</style>