export default {
  sendMail: async (context, payload) => {
    const url = context.rootGetters.url("mail")
    let result = new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest()
      xhr.open('POST', url, true)
      xhr.send(payload)
      xhr.onload = () => {
        let response = JSON.parse(xhr.response)
        if (response.status) {
          resolve(xhr.response)
        } else {
          reject(xhr.response)
        }
      }
    })
    return result
  }
}