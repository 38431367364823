<template>
  <div id="site-header-wrap" class="absolutee">
    <div id="top-bar">
      <div id="top-bar-inner" class="ready-container"></div>
    </div>

    <header id="site-header" v-if="services">
      <div id="site-header-inner" class="ready-container">
        <div class="wrap-inner">
          <div id="site-logo" class="clearfix">
            <div id="site-logo-inner">
              <router-link to="/" title="Ready" rel="home" class="main-logo">
                <HeaderLogo />
              </router-link>
            </div>
          </div>
          <div class="top-bar-inner-wrap" v-if="info">
            <div class="top-bar-content">
              <span class="phone content">
                <a :href="`tel:00964${info.main_phone}`">
                  <i class="finance-icon-Phone"></i> &nbsp;&nbsp;
                  {{ info.main_phone }} &nbsp;&nbsp;
                </a>
              </span>
              <span> &nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span class="address content">
                <a :href="`mailto:${info.main_email}`" title="mail">
                  <i class="finance-icon-Email02"></i> &nbsp;&nbsp;
                  {{ info.main_email }} &nbsp;&nbsp;
                </a>
              </span>
            </div>

            <div
              class="top-bar-content-right"
              v-if="info.facebook || info.linkedin"
            >
              <div class="top-bar-socials">
                <div class="inner">
                  <span class="icons">
                    <a
                      v-if="info.facebook"
                      :href="info.facebook"
                      title="Facebook"
                      target="_blank"
                    >
                      |&nbsp;&nbsp;
                      <span
                        class="finance-icon-Facebook"
                        aria-hidden="true"
                      ></span>
                    </a>
                    <a
                      v-if="info.linkedin"
                      :href="info.linkedin"
                      title="Linkedin"
                      target="_blank"
                    >
                      &nbsp;&nbsp;
                      <span
                        class="finance-icon-Instagram"
                        aria-hidden="true"
                      ></span>
                    </a>
                    <a
                      v-if="info.instagram"
                      :href="info.instagram"
                      title="Instagram"
                      target="_blank"
                    >
                      &nbsp;&nbsp;
                      <InstagramIcon />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="mobile-button"><span></span></div>
        </div>
      </div>
    </header>
    <nav id="main-nav" class="main-nav">
      <ul id="menu-primary-menu" class="menu nav-container">
        <li class="menu-item menu-item-has-children">
          <router-link to="/">HOME </router-link>
        </li>
        <li class="menu-item menu-item-has-children">
          <router-link to="/about">ABOUT </router-link>
        </li>
        <li
          class="menu-item menu-item-has-children"
          v-for="service in services"
          :key="service.id"
        >
          <router-link :to="`/services/${service.id}`">{{
            service.title
          }}</router-link>
        </li>
        <li class="menu-item menu-item-has-children">
          <router-link to="/projects"> PROJECTS </router-link>
        </li>
        <li class="menu-item menu-item-has-children">
          <router-link to="/agencies"> AGENCIES </router-link>
        </li>
        <li class="menu-item menu-item-has-children">
          <router-link to="/contact">CONTACT </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import HeaderLogo from "./HeaderLogo.vue";
import InstagramIcon from "./InstagramIcon.vue";

export default {
  components: { HeaderLogo, InstagramIcon },
  props: ["services"],
  computed: {
    info() {
      return this.$store.getters["pages/contact"];
    },
  },
};
</script>

<style>
#main-nav {
  display: grid;
  place-items: center;
  text-transform: uppercase;
  background-color: #f4f4f4;
  width: 100%;
  transition: 400ms;
  position: relative;
}
</style>

<style scoped>
.wrap-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.wrap-inner a {
  color: #444;
  font-weight: 700;
}
</style>