import useApi from '../../useApi'

export default {
  services: async context => {
    const url = context.rootGetters.url('services')
    const response = await useApi.get(url)
    context.commit('setServices', response.services)
  },
  service: async (context, payload) => {
    const url = context.rootGetters.url(`services/${payload}`)
    const response = await useApi.get(url)
    context.commit('setService', response.service)
  },
  subservice: async (context, payload) => {
    const url = context.rootGetters.url(`subservices/${payload}`)
    const response = await useApi.get(url)
    context.commit('setSubservice', response.subservice)
  },
  suppliers: async context => {
    const url = context.rootGetters.url('suppliers')
    const response = await useApi.get(url)
    context.commit('setSuppliers', response.suppliers)
  },
  supplier: async (context, payload) => {
    const url = context.rootGetters.url(`suppliers/${payload}`)
    const response = await useApi.get(url)
    context.commit('setSupplier', response.supplier)
  },
  projects: async (context, payload) => {
    let action = 'projects/latest'
    if (payload) {
      action = 'projects/all'
    }

    const url = context.rootGetters.url(action)
    const response = await useApi.get(url)
    context.commit('setProjects', response.projects)
  },
  clients: async context => {
    const url = context.rootGetters.url('clients')
    const response = await useApi.get(url)
    context.commit('setClients', response.clients)
  }
}