<template>
  <TheLoader v-if="loading" />
  <div v-else-if="supplier">
    <CertificateModal
      v-if="supplier?.certificate && viewCertificate"
      :image="supplier.certificatePath"
      @close="closeModal"
    />

    <div id="main-content" class="site-main clearfix">
      <div id="content-wrap">
        <div id="site-content" class="site-content clearfix">
          <div id="inner-content" class="inner-content-wrap">
            <div class="page-content">
              <div class="container">
                <div class="row clearfix spacer-t-md spacer-b-md">
                  <div class="col-md-8 spacer-b-sm">
                    <swiper
                      :style="`height: ${imageHeight}px; width:100% margin-bottom: 50px; border-radius: 15px`"
                      :slides-per-view="1"
                      :space-between="15"
                      :navigation="navigation"
                      :observer="true"
                      :observeParents="true"
                    >
                      <button id="prev-el">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.867"
                          height="11.234"
                          viewBox="0 0 6.867 11.234"
                        >
                          <g
                            id="chevron-down_4_"
                            data-name="chevron-down (4)"
                            transform="translate(1.25 9.466) rotate(-90)"
                          >
                            <path
                              id="Path"
                              d="M0,3.849,3.849,0,7.7,3.849"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-miterlimit="10"
                              stroke-width="3"
                            />
                          </g>
                        </svg>
                      </button>
                      <button id="next-el">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.867"
                          height="11.234"
                          viewBox="0 0 6.867 11.234"
                        >
                          <g
                            id="chevron-down_4_"
                            data-name="chevron-down (4)"
                            transform="translate(1.768 9.466) rotate(-90)"
                          >
                            <path
                              id="Path"
                              d="M0,0,3.849,3.849,7.7,0"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-miterlimit="10"
                              stroke-width="3"
                            />
                          </g>
                        </svg>
                      </button>
                      <swiper-slide
                        v-for="(image, index) in supplier.photos"
                        :key="index"
                        style="width: 100%"
                      >
                        <div
                          class="inner"
                          :style="`height: ${imageHeight}px;
                          max-width: 100%;
                          background-color: #eee;
                          background-image: url(${image});
                          background-size: cover;
                          border-radius:15px;
                          background-position: center;`"
                        ></div>
                      </swiper-slide>
                    </swiper>
                  </div>

                  <div class="col-md-4">
                    <div class="ready-portfolio-detail style-1">
                      <div class="inner">
                        <div class="text-wrap">
                          <img :src="supplier.logoPath" class="supplier-item" />
                          <h6 class="title spacer-t-sm">
                            <span>
                              {{ supplier.title }}
                            </span>
                          </h6>
                          <div class="desc spacer-b-sm">
                            <span v-html="supplier.summary"> </span>
                          </div>
                          <ul class="portfolio-meta">
                            <li>
                              SUPPLIER <span>{{ supplier.name }}</span>
                            </li>
                            <li>
                              CATEGORY
                              <span> {{ supplier.service.title }} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row clearfix spacer-b-md"
                  v-if="supplier.content || supplier.certificate"
                >
                  <hr class="separator" />
                  <div
                    class="col-xs-12 col-sm-6 col-md-4 spacer-t-sm"
                    v-if="supplier.certificate"
                  >
                    <div class="cert-pill">Certificate</div>
                    <img
                      :src="supplier.certificatePath"
                      @click="openModal"
                      class="certificate-small"
                    />
                  </div>
                  <div
                    :class="
                      supplier.certificate
                        ? 'col-xs-12 col-sm-6 col-md-8'
                        : 'col-lg-12'
                    "
                    class="spacer-t-sm"
                    v-for="(item, index) in supplier.content"
                    :key="index"
                  >
                    <h6 class="title" style="color: #a10000">
                      <span>
                        {{ item.header }}
                      </span>
                    </h6>
                    <div class="desc spacer-b-sm">
                      <span v-html="item.body"> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import CertificateModal from "../components/CertificateModal.vue";
import TheLoader from "../components/TheLoader.vue";

SwiperCore.use([Navigation]);

export default {
  components: { Swiper, SwiperSlide, CertificateModal, TheLoader },
  props: ["id"],
  data() {
    return {
      viewCertificate: false,
      navigation: {
        nextEl: "#next-el",
        prevEl: "#prev-el",
        disabledClass: "disabled-nav-button",
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        reverseDirection: false,
      },
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch("services/supplier", this.id);
    this.loading = false;
  },
  computed: {
    supplier() {
      let c = this.$store.getters["services/supplier"];
      return c;
    },
    imageHeight() {
      let width = document.documentElement.clientWidth;
      return width > 991 ? 500 : width > 600 ? 400 : 300;
    },
  },
  methods: {
    openModal() {
      this.viewCertificate = true;
    },
    closeModal() {
      this.viewCertificate = false;
    },
  },
};
</script>

<style scoped>
#prev-el {
  position: absolute;
  top: 48%;
  left: 10px;
  z-index: 100;
}

#next-el {
  position: absolute;
  top: 48%;
  right: 10px;
  z-index: 100;
}

.cert-button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
}

.title {
  font-size: 18px;
}

.cert-pill {
  position: absolute;
  background-color: #a70000;
  color: #fff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-weight: 700;
  padding: 10px 35px;
  top: 15px;
  right: 15px;
  font-size: 18px;
}

.certificate-small {
  border: 2px solid #e9e9e9;
  cursor: pointer;
  border-radius: 10px;
}
</style>

<style >
.disabled-nav-button {
  opacity: 0;
  cursor: default;
}
</style>