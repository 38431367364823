<template>
  <div class="row-partner">
    <div class="container">
      <div class="ready-headings style-1 clearfix">
        <h2 class="heading clearfix">OUR CLIENTS</h2>
        <div class="sep clearfix"></div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="ready-partner style-2 has-bullets bullet-circle bullet40">
            <swiper
              class="partners-slider custom-pagination"
              :slides-per-view="slidesNumber"
              :pagination="pagination"
              :autoplay="autoplay"
            >
              <swiper-slide
                class="partner-item clearfix"
                v-for="(item, index) in clients"
                :key="index"
              >
                <ClientsCard
                  :id="index"
                  :title="item.name"
                  :logo="item.fullPath"
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import ClientsCard from "./ClientsCard.vue";

SwiperCore.use([Pagination, Autoplay]);

export default {
  props: ["clients"],
  components: { ClientsCard, Swiper, SwiperSlide },
  data() {
    return {
      pagination: {
        clickable: false,
        bulletActiveClass: "pagination-active",
      },
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
        reverseDirection: false,
      },
    };
  },
  computed: {
    slidesNumber() {
      let width = document.documentElement.clientWidth;
      return width > 700 ? 5 : width > 500 ? 3 : 2;
    },
  },
};
</script>

<style scoped>
.row-partner {
  margin-top: 50px;
}

.partners-slider {
  margin-bottom: 40px;
}

.clearfix {
  margin-bottom: 10px;
}
</style>

<style>
.custom-pagination > .swiper-pagination-bullets {
  bottom: -5px;
}
</style>