<template>
  <CertificateModal
    v-if="viewMagazine"
    :image="images[currentImage]"
    @close="closeMagazine"
  />
  <TheLoader v-if="loading" />
  <div id="main-content" class="site-main clearfix" v-else-if="about">
    <div
      id="main-photo"
      :style="`
        width: 100%;
        background-image: url(${about.fullPath});
        background-size: cover;
        background-position: center;
      `"
    ></div>
    <div id="content-wrap">
      <div id="site-content" class="site-content clearfix">
        <div id="inner-content" class="inner-content-wrap">
          <div class="page-content">
            <div class="row-video">
              <div class="container">
                <div class="row spacer-t-lg">
                  <div class="col-md-12">
                    <div class="ready-headings style-1 clearfix">
                      <h2 class="heading clearfix">
                        {{ about.title }}
                      </h2>
                      <div class="sep clearfix spacer-b-sm"></div>
                      <span v-html="about.content" class="sub-heading clearfix">
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="row equalize sm-equalize-auto spacer-b-sm spacer-t-md"
                >
                  <div class="col-md-12">
                    <div class="ready-tabs clearfix style-1">
                      <ul class="tab-title clearfix">
                        <li
                          class="item-title"
                          v-for="(tab, index) in about.tabs"
                          :key="tab.id"
                          :class="{ active: index === 0 }"
                        >
                          <span class="inner">{{ tab.title }}</span>
                        </li>
                      </ul>

                      <div class="tab-content-wrap">
                        <div
                          class="tab-content"
                          v-for="tab in about.tabs"
                          :key="tab.id"
                        >
                          <div class="item-content col-md-6">
                            <h3 class="heading">
                              <br />
                              {{ tab.header }}
                            </h3>
                            <span v-html="tab.content"></span>
                          </div>
                          <div
                            class="col-md-6 half-background style-1"
                            :style="`
                              background-image: url(${tab.fullPath});
                              background-size: contain;
                              min-height: 400px;
                              margin-top: 50px;`"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-accordions bg-light-grey spacer-t-sm">
              <div class="container">
                <div class="row equalize sm-equalize-auto">
                  <div class="col-md-6">
                    <div class="img-single margin-top--60">
                      <img :src="about.serving.fullPath" alt="Image" />
                    </div>
                  </div>

                  <div
                    class="col-md-6 item-content spacer-t-md"
                    id="whoweserve"
                  >
                    <div
                      class="ready-headings style-1 clearfix padding-left-27"
                    >
                      <h2 class="heading clearfix">
                        {{ about.serving.title }}
                      </h2>
                      <div class="sep clearfix"></div>
                    </div>

                    <ul
                      style="
                        list-style: none;
                        margin-top: 30px;
                        margin-left: 20px;
                      "
                    >
                      <span
                        v-html="about.serving.content"
                        class="serve-content"
                      ></span>
                      <p class="serve-last">Others and Others...</p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="spacer-b-md spacer-t-md">
              <div class="container">
                <div class="ready-headings style-1 clearfix">
                  <h2 class="heading clearfix">
                    Featured in Global Cement Magazine
                  </h2>
                  <div class="sep clearfix"></div>
                </div>
                <div class="magazine-wrapper">
                  <div
                    v-for="(image, index) in images"
                    :key="index"
                    @click="openMagazine(index)"
                  >
                    <img :src="image" />
                  </div>
                </div>
              </div>
            </div>
            <ProjectsSlider />
            <ClientsSlider :clients="clients" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ClientsSlider from "../components/ClientsSlider.vue";
import ProjectsSlider from "../components/ProjectsSlider.vue";
import TheLoader from "../components/TheLoader.vue";
import CertificateModal from "../components/CertificateModal.vue";

export default {
  components: { ClientsSlider, ProjectsSlider, TheLoader, CertificateModal },
  data() {
    return {
      loading: true,
      viewMagazine: false,
      images: ["/assets/img/magazine1.jpg", "/assets/img/magazine2.jpg"],
      currentImage: null,
    };
  },
  computed: {
    about() {
      return this.$store.getters["pages/about"];
    },
    clients() {
      return this.$store.getters["services/clients"];
    },
  },
  async mounted() {
    await this.$store.dispatch("pages/about");
    await this.$store.dispatch("services/clients");
    this.loading = false;
    this.$nextTick(() => {
      this.fixTabs();
    });
  },
  inject: ["imagePath"],
  methods: {
    fixTabs() {
      $(".ready-tabs").each(function () {
        var list = "",
          title = $(this).find(".item-title"),
          titleWrap = $(this).children(".tab-title");

        title
          .each(function () {
            list = list + "<li>" + $(this).html() + "</li>";
          })
          .appendTo(titleWrap);

        $(this).find(".tab-title li").filter(":first").addClass("active");
        $(this)
          .find(".tab-content-wrap")
          .children()
          .hide()
          .filter(":first")
          .show();

        $(this)
          .find(".tab-title li")
          .on("click", function (e) {
            var idx = $(this).index(),
              content = $(this)
                .closest(".ready-tabs")
                .find(".tab-content-wrap")
                .children()
                .eq(idx);

            $(this).addClass("active").siblings().removeClass("active");
            content.fadeIn("slow").siblings().hide();

            e.preventDefault();
          });
      });
    },
    openMagazine(index) {
      this.currentImage = index;
      this.viewMagazine = true;
    },
    closeMagazine() {
      this.viewMagazine = false;
      this.currentImage = null;
    },
  },
};
</script>

<style scoped>
#main-photo {
  height: 450px;
}

.serve-content {
  font-size: 24px;
  font-weight: 800;
  color: #000;
}

.serve-last {
  color: orange;
  font-size: 24px;
  font-weight: 800;
}

.magazine-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-right: 10px;
}

.magazine-wrapper > div {
  width: 50%;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #d6d6d6;
  margin: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #main-photo {
    height: 300px;
  }

  #whoweserve {
    padding: 30px 15px;
  }

  .serve-content,
  .serve-last {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .magazine-wrapper {
    flex-direction: column;
  }

  .magazine-wrapper > div {
    width: 100%;
  }
}
</style>