<template>
  <ProjectsGallery
    v-if="projects && gallery"
    :projects="projects"
    :show="gallery"
    :initial-slide="initialSlide"
    page="projects"
    @close="closeGallery"
  />

  <TheLoader v-if="loading" />
  <div id="main-content" class="site-main clearfix" v-else-if="projects">
    <div id="content-wrap">
      <div id="site-content" class="site-content clearfix">
        <div id="inner-content" class="inner-content-wrap">
          <div class="page-content">
            <div class="row-partner">
              <div class="container spacer-b-md spacer-t-sm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="ready-headings style-1">
                      <h2 class="heading clearfix">Projects</h2>
                      <div class="sep clearfix page-title"></div>
                      <!-- <div> -->
                      <div
                        v-for="(project, index) in projects"
                        :key="index"
                        class="gallery-box col-xs-12 col-sm-6 col-md-4 spacer-b-sm"
                      >
                        <ProjectCard
                          :index="index"
                          :title="project.title"
                          :image="project.photo"
                          @selectImage="openGallery"
                        />
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsGallery from "../components/ProjectsGallery.vue";
import ProjectCard from "../components/ProjectCard.vue";
import TheLoader from "../components/TheLoader.vue";

export default {
  components: {
    ProjectsGallery,
    ProjectCard,
    TheLoader,
  },
  data() {
    return {
      loading: true,
      gallery: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("services/projects", "all");
    this.loading = false;
  },
  computed: {
    projects() {
      return this.$store.getters["services/projects"];
    },
  },
  methods: {
    openGallery(index) {
      this.initialSlide = index;
      this.gallery = true;
    },
    closeGallery() {
      this.gallery = false;
    },
  },
};
</script>

<style scoped>
.gallery-box {
  border-radius: 15px;
  overflow: hidden;
}
</style>