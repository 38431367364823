import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      services: [],
      service: null,
      subservice: null,
      suppliers: [],
      supplier: null,
      projects: [],
      clients: [],
    }
  },
  mutations,
  actions,
  getters
}