<template>
  <TheLoader v-if="loading" />
  <div id="main-content" class="site-main clearfix" v-else-if="home">
    <div id="content-wrap">
      <div id="site-content" class="site-content clearfix">
        <div id="inner-content" class="inner-content-wrap">
          <div class="page-content">
            <div class="row-services">
              <swiper
                :speed="700"
                :slides-per-view="1"
                effect="fade"
                :loop="true"
                :autoplay="autoplay"
                :fadeEffect="fadeEffect"
                :pagination="mainPagination"
                @slideChangeTransitionStart="beforeSlideChange"
                @slideChangeTransitionEnd="afterSlideChange"
              >
                <swiper-slide v-for="slide in home.sliders" :key="slide.id">
                  <div
                    :style="`height: ${mainPhotoHeight}px;
                          background-color: #eee;
                          background-image: url(${slide.fullPath});
                          background-size: cover;
                          width: 100%;
                          background-position: center;`"
                  >
                    <div class="image-text container">
                      <div
                        class="slide-content"
                        v-html="slide.content"
                        style="color: white"
                      ></div>
                      <router-link
                        to="/about"
                        class="ready-button white big"
                        style="color: #999"
                      >
                        LEARN MORE
                      </router-link>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="container">
                <div class="row equalize sm-equalize-auto spacer-t-lg">
                  <div class="col-md-6 spacer-t-sm">
                    <div class="ready-headings style-1 clearfix">
                      <h2 class="heading letter-spacing--09px clearfix">
                        {{ home.title }}
                      </h2>
                      <div class="sep clearfix"></div>
                      <br />
                      <div v-html="home.content" class="sub-heading clearfix"></div>
                    </div>

                    <h3 class="title-video spacer-b-sm">
                      Driven by Quality & Innovation
                    </h3>

                    <router-link to="/about" class="ready-button blue"
                      >READ MORE ABOUT US</router-link
                    >
                  </div>

                  <!-- background-image: url(/assets/img/about.png); -->
                  <div
                    class="col-md-6 half-background style-1"
                    :style="`
                      background-image: url(${home.fullPath ?? '/assets/img/about.png'});
                      background-size: contain;
                      height: 400px;
                      margin-bottom: 15px;
                      margin-top: 30px;
                    `"
                  ></div>
                </div>
                <div class="border-h-1 dark spacer-t-md spacer-b-md"></div>
              </div>
              <div class="container">
                <div class="row spacer-b-md">
                  <div class="col-md-12 spacer-b-md">
                    <div class="ready-headings style-1">
                      <h2 class="heading clearfix">Our Services</h2>
                      <div class="sep clearfix"></div>
                    </div>
                  </div>
                  <ServicesSlider />
                </div>
              </div>
            </div>

            <div class="row-promotion bg-promo-box spacer-b-sm">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="ready-action-box style-2">
                      <div class="inner" style="padding-top: 40px; padding-bottom: 30px">
                        <div class="heading-wrap">
                          <div class="text-wrap" style="padding-left: 0">
                            <h3 class="heading">What you need ? Only contact us</h3>
                            <p>Expect nothing less from us – that’s who we are</p>
                            <span class="icon"><i class="as-icon-speedometer2"></i></span>
                          </div>
                        </div>
                        <div class="button-wrap">
                          <router-link
                            to="/contact"
                            class="ready-button azure font-weight-600"
                            >CONTACT US
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ProjectsSlider />

            <ClientsSlider :clients="clients" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import ServicesSlider from "../components/ServicesSlider.vue";
import ProjectsSlider from "../components/ProjectsSlider.vue";
import ClientsSlider from "../components/ClientsSlider.vue";
import TheLoader from "../components/TheLoader.vue";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

import $ from "jquery";

export default {
  components: {
    Swiper,
    SwiperSlide,
    ServicesSlider,
    ProjectsSlider,
    ClientsSlider,
    TheLoader,
  },
  data() {
    return {
      pagination: {
        clickable: true,
        bulletActiveClass: "pagination-active",
      },
      mainPagination: {
        clickable: true,
        bulletActiveClass: "main-bullet-active",
        bulletClass: "swiper-pagination-bullet main-bullet",
      },
      projectsNav: {
        nextEl: "#next-el",
        prevEl: "#prev-el",
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        reverseDirection: false,
        waitForTransition: true,
      },
      fadeEffect: {},
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch("pages/home");
    await this.$store.dispatch("services/clients");
    this.loading = false;
  },
  computed: {
    home() {
      return this.$store.getters["pages/home"];
    },
    mainPhotoHeight() {
      let width = document.documentElement.clientWidth;
      return width > 700 ? 550 : 300;
    },
    partners() {
      return this.$store.getters["services/partners"];
    },
    projects() {
      return this.$store.getters["services/projects"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    clients() {
      return this.$store.getters["services/clients"];
    },
  },
  methods: {
    beforeSlideChange() {
      let width = document.documentElement.clientWidth;
      $(".image-text").animate({ opacity: 0, top: width > 800 ? 70 : 20 }, 500);
    },
    afterSlideChange() {
      let width = document.documentElement.clientWidth;
      $(".image-text").animate({ opacity: 1, top: width > 800 ? 120 : 40 }, 700);
    },
  },
};
</script>

<style scoped>
.slide-title {
  line-height: 66px;
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 52px;
  white-space: normal;
  min-height: 0px;
  min-width: 1170px;
  max-height: none;
}

.slide-subtitle {
  line-height: 34px;
  border-width: 0px;
  margin: 0px;
  margin-top: 40px;
  padding: 0px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  white-space: normal;
  min-height: 0px;
  min-width: 1170px;
  max-height: none;
}

.partners-slider {
  /* margin-top: 20px; */
  margin-bottom: 40px;
}

.clearfix {
  margin-bottom: 10px;
}

.projects-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.image-text {
  padding-left: 50px;
  position: relative;
}

.image-text span,
.image-text > span > h2 {
  color: #fff !important;
}

.image-text a {
  margin-top: 40px;
}

@media only screen and (max-width: 800px) {
  .image-text a {
    margin-top: 15px;
    font-size: 12px;
    color: #fff;
  }

  .image-text {
    padding-left: 20px;
  }
}
</style>

<style>
.pagination-active {
  background: #a70000;
  opacity: 1;
}

#next-el,
#prev-el {
  margin-left: 2px;
  margin-right: 2px;
  padding: 9px 12px;
  border-radius: 50%;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-bullet {
  background: transparent;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  opacity: 1;
}

.main-bullet-active {
  background: #fff;
  opacity: 1;
}

.slide-content * {
  color: #fff;
}

.slide-content h2 {
  line-height: 66px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 52px;
}

.slide-content p {
  line-height: 0px;
  margin-top: 40px;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 20px;
}
@media only screen and (max-width: 800px) {
  /* .slide-title, */
  .slide-content h2 {
    line-height: 30px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: -1px;
    font-weight: 700;
    font-size: 27px;
    white-space: normal;
    min-height: 0px;
    min-width: 1170px;
    max-height: none;
  }

  /* .slide-subtitle, */
  .slide-content p {
    line-height: 8px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 16px;
    white-space: normal;
    min-height: 0px;
    min-width: 1170px;
    max-height: none;
  }
}
</style>
